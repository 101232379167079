body,
header,
nav,
section,
footer,
aside,
div,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
table,
tr,
td,
span,
p,
img,
textarea,
pre,
form,
input,
button,
html,
header,
main,
footer,
a {
  box-sizing: border-box;
  line-height: 120%;
  margin: 0;
  padding: 0;
}