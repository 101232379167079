.header {

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 3px solid @color02;
    padding: 15px 0;
  }

  &_logo {
    img {
      width: 250px;
      height: 43px;
      margin-bottom: -5px;
      margin-top: 5px;
    }
  }

  nav {
    display: flex;
    align-items: center;

    ul {
      display: flex;
      align-items: center;
    }

    li {
      list-style-type: none;
      margin-left: 40px;

      a, span {
        font-size: 16px;
        color: @color02;
        font-weight: 800;
        text-decoration: none;

        &:hover {
          color: @colorlink;
          border-bottom: 1px solid @colorlink;
        }
      }

      .current-menu-item {
        color: @colorlink;
        border-bottom: 1px solid @colorlink;
      }
    }
  }
}

.icon-search {
  display: flex;
  align-items: center;

  &:before {
    content: '';
    display: flex;
    cursor: pointer;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-size: 95%;
    background-image: url('../img/icon-search.svg');
  }
}

.search {

  form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  input {
    outline: none;
    border: none;
    font-family: @font01;
  }

  .text {
    width: 80%;
    height: 50px;
    font-size: 18px;
    border-bottom: 1px solid @color02;
    outline: none;
  }

  .submit {
    width: 10%;
    height: 40px;
    cursor: pointer;
    font-weight: 800;
    font-size: 14px;
    text-transform: uppercase;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }

  &-inner {
    display: flex;
    visibility: hidden;
    width: 0;
    overflow: hidden;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    background: @color01;
    transition: width 500ms ease, visibility 500ms ease;
  }

  &-expanded {
    width: calc(100% - 280px);
    visibility: visible;
  }

  &-close {
    width: 40px;
    height: 40px;
    cursor: pointer;
    opacity: 0.8;

    &:before {
      background-image: url('../img/icon-close.svg');
      margin-right: 0;
      width: 40px;
      height: 40px;
    }

    &:hover {
      opacity: 1;
    }
  }
}

.menu-open {
  width: 40px;
  height: 40px;
  cursor: pointer;
  opacity: 0.8;
  margin: 0 10px 0 35px;

  &:before {
    background-image: url('../img/icon-menu.svg');
    margin-right: 0;
    width: 40px;
    height: 40px;
  }

  &:hover {
    opacity: 1;
  }
}

/* Адаптивная верстка */
@media (max-width: 1200px) {

}

@media (max-width: 1023px) {
  #menu-mainmenu {
    display: none;
  }

  .search {
    .text {
      width: 60%;
    }

    .submit {
      width: 20%;
    }
  }
}

@media (max-width: 767px) {
  .search-expanded {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .header_logo img {
    width: 200px;
    height: 35px;
  }
}