.footer {

  &-logo {
    img {
      width: 200px;
      height: 35px;
    }
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0 0;
  }

  .footermenu {
    .wrapper {
      border-top: 3px solid @color02;
    }
  }

  nav {
    display: flex;
    align-items: center;

    ul {
      display: flex;
      align-items: center;
    }

    li {
      display: flex;
      align-items: center;
      list-style-type: none;
      margin-left: 40px;

      a, span {
        font-size: 16px;
        color: @color02;
        font-weight: 700;
        text-decoration: none;

        &:hover {
          color: @colorlink;
          border-bottom: 1px solid @colorlink;
        }
      }

      .current-menu-item {
        color: @colorlink;
        border-bottom: 1px solid @colorlink;
      }
    }
  }

  .techmenu-inner {
    .wrapper {
      padding: 15px 0;
      border-top: 1px solid @color04;
      margin-top: 30px;
    }

    nav {
      ul li {
        a, span {
          font-size: 12px;
          color: @color02;
          font-weight: 400;
          text-transform: lowercase;
        }
      }
    }
  }

  &-copyrights {
    color: @color02;
    font-size: 12px;
  }
}

.fullmenu {
  position: fixed;
  left: -300px;
  top: 0;
  bottom: 0;
  width: 300px;
  height: 100%;
  background-color: @color01;
  z-index: 10;
  padding: 25px;
  transition: left 500ms;

  img {
    width: 180px;
    height: 31px;
    margin-bottom: 15px;
  }

  ul {
    list-style: none;
    border-top: 3px solid @color02;
    padding-top: 20px;

    li {
      a, span {
        font-size: 18px;
        font-weight: 800;
        text-decoration: none;
        display: inline-block;
        margin: 5px 0;
        color: @color02;
        border-bottom: 1px solid @color01;
      }

      a:hover {
        color: @colorlink;
        border-bottom: 1px solid @colorlink;
      }

      .current-menu-item {
        color: @colorlink;
        border-bottom: 1px solid @colorlink;
      }
    }
  }

  &-close {
    display: block;
    position: absolute;
    top: 20px;
    right: 18px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    opacity: 0.8;

    &:before {
      background-image: url('../img/icon-close.svg');
      margin-right: 0;
      width: 40px;
      height: 40px;
    }

    &:hover {
      opacity: 1;
    }
  }

  &.show {
    left: 0;
  }
}

.fadeblock {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  backdrop-filter: blur(3px);
  opacity: 0;
  transition: opacity 500ms;

  &.show {
    display: block;
    opacity: 1;
  }
}

/* Адаптивная верстка */
@media (max-width: 1200px) {

}

@media (max-width: 1023px) {

}

@media (max-width: 767px) {
  .footer {
    .footermenu .wrapper {
      flex-wrap: wrap;
    }

    &-logo {
      margin-bottom: 20px;
    }

    nav {
      li:first-child {
        margin-left: 0;
      }
    }
  }
}

@media (max-width: 480px) {

}