.comments {
  width: 100%;

  &-wrapper {
    clear: both;
    width: 100%;
    max-width: @contentwidth;
    margin: 10px auto;
    font-size: 15px;
  }

  &-inner {
    border-top: 1px solid @color04;
    padding-top: 30px;
  }

  ul {
    list-style: none;
  }

  ul.children {
    margin-left: 30px;

    p {
      margin-bottom: 30px !important;
    }
  }

  p {
    margin: 10px 0;
    font-size: 15px;
    line-height: 25px;
    opacity: 0.8;
  }

  a {
    color: @colorlink;
    font-size: 14px;
  }

  a:hover {
    color: @colorhover;
  }

  .logged-in-as a:nth-child(2) {
    float: right;
  }

  &title {
    font-size: 25px;
    font-weight: 700;
  }

  &items {
    padding: 0;
    margin: 30px 0 0 0;
  }

  &author {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    text-transform: capitalize;
    opacity: 0.8;
  }

  &author:before {
    content:url('../img/icon-user.svg');
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  &date {
    //    margin-right: 30px;
    position: absolute;
    top: 35px;
    right: 30px;
    font-size: 12px;
    opacity: 0.5;
  }

  &text p:first-letter {
    text-transform: uppercase;
  }

  &addtitle {
    display: block;
    font-size: 20px;
    padding: 30px 0 0 0;
  }

  .comment-form {
    margin-bottom: 30px;

    input, textarea {
      font-family: @font01;
      font-size: 14px;
      font-weight: 300;
      display: block;
      padding: 10px;
      border: 1px solid @color04;
      margin: 20px 0;
      border-radius: 10px;
    }

    input.author {
      width: 250px;
    }

    input.email {
      width: 350px;
    }

    textarea {
      width: 100%;
    }

    input.submit {
      cursor: pointer;
      font-size: 14px;
      //text-transform: uppercase;
      font-weight: 700;
      padding: 12px 20px;
      color: @colorlink;
      border-radius: 10px;
      border: none;
    }

    input.submit:hover {
      color: @colorhover;
    }
  }

  .comment {
    position: relative;
    margin: 0 0 30px 0;
    padding: 30px;
    background: @color03;
    border-radius: 10px;

    &-respond {
      border-bottom: 1px solid @color04;
      margin-bottom: 30px;
    }
  }

  .comment0 {
    font-style: italic;
    margin-bottom: 30px;
  }

  .comment-form-cookies-consent {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-style: italic;

    label {
      margin-left: 10px;
      font-size: 14px;
    }
  }
}

/* Адаптивная верстка - Десктоп */
@media (max-width: 1260px) and (min-width: 1024px) {

}

/* Адаптивная верстка - Планшеты */
@media (max-width: 1023px) {

}

/* Адаптивная верстка - Мини планшеты */
@media (max-width: 767px) {
  .comments-wrapper {

  }
}

/* Адаптивная верстка - Мобильники */
@media (max-width: 480px) {
  .comments .comment-form input.email {
    width: 350px;
  }
}