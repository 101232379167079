@color01: #ffffff;
@color02: #302e28;
@color03: #f7f7f7;
@color04: #e0e0e0;

@colorlink: #7b2724;
@colorhover: #ff4841;

@sitemaxwidth: 1140px;
@contentwidth: 900px;

@font01: 'Montserrat', sans-serif;

body {
  font-family: @font01;
  font-size: 18px;
  line-height: 28px;
  color: @color02;
  background: @color01;
  font-variant-numeric: lining-nums;
  overflow-x: hidden;
}

.wrapper {
  position: relative;
  width: 100%;
  max-width: @sitemaxwidth;
  margin: 0 auto;
  z-index: 1;
}

.content {
  width: 100%;

  p {
    margin: 30px 0;
    line-height: 28px;
  }
}

.breadcrumbs {
  display: flex;

  > span {
    background: @color04;
    padding: 0 10px 2px;
    margin: 5px 10px 5px 0;
    border-radius: 5px;

    a, span {
      font-size: 14px;
      color: @color02;
      text-decoration: none;
      opacity: 0.8;

      &:hover {
        text-decoration: underline;
        opacity: 1;
      }
    }
  }

  &-item:first-child {
    display: none;
  }
}

strong {
  font-weight: 800;
}

h2 {
  margin: 50px 0 0;
  font-size: 35px;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
  content: '';
  display: flex;
  width: 25px;
  height: 25px;
  margin-right: 5px;
  background-repeat: no-repeat;
  background-size: 100%;
}

.icon-view:before {
  background-image: url('../img/icon-views.svg');
  margin-right: 5px;
  background-size: 90%;
}

.icon-comments:before  {
  background-image: url('../img/icon-comments.svg');
  background-size: 80%;
  margin-top: 3px;
}

.icon-time:before  {
  background-image: url('../img/icon-time.svg');
  background-size: 70%;
  margin-top: 6px;
}

/* Адаптивная верстка */
@media (max-width: 1200px) {
  .wrapper {
    width: 95%;
  }
}

@media (max-width: 1023px) {

}

@media (max-width: 767px) {
  .wrapper {
    width: 90%;
  }

  h2 {
    margin: 30px 0 0;
    font-size: 28px;
  }
}

@media (max-width: 480px) {

}