.page {
  .post-text {
    width: 100%;
  }

  h1 {
    font-size: 45px;
    line-height: 55px;
    margin: 50px 0 40px;
  }
}

.mainpage_posts {
  flex-direction: row;
  justify-content: space-between;
  width: 1140px;
  padding-top: 30px;
}

.team {
  &-item {
    margin-top: 40px;

    &-header {
      display: flex;
      align-items: center;
      margin-top: 20px;

      img {
        margin: 0 !important;
        border-radius: 10px;
      }
    }

    &-text {
      display: flex;
      flex-direction: column;
      margin-left: 30px;
    }

    &-name {
      font-size: 32px;
      line-height: 32px;
      font-weight: 800;
    }

    &-position {
      font-size: 18px;
      line-height: 24px;
      font-weight: 400;
      margin-top: 10px;
    }
  }
}

.wpcf7 {
  padding: 0;
  position: relative;

  .title {
    display: flex;
    font-size: 24px;
    line-height: 35px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  label {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    line-height: 22px;
  }

  input, textarea, select {
    font-family: @font01;
    font-size: 16px;
    display: inline-block;
    padding: 15px;
    border: 1px solid @color04;
    margin: 0 0 15px 0;
    width: 100%;
    border-radius: 5px;
    outline: none;
    color: @color02;
  }

  select {
    padding-left: 12px;
  }

  textarea {
    height: 150px;
  }

  .wpcf7-submit {
    width: 200px;
    cursor: pointer;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 800;
    padding: 15px 20px;
    border-radius: 10px;
    background-color: @colorlink;
    color: @color01;

    &:hover {
      color: @color03;
      opacity: 0.8;
    }

    &:disabled {
      opacity: 0.5;
    }
  }

  form.sent .wpcf7-response-output, form .wpcf7-response-output {
    margin: 20px 0 0 !important;
    padding: 15px !important;
    font-size: 14px;
    line-height: 20px;
    border-radius: 5px;
    border: 1px solid @color04 !important;
  }

  .wpcf7-not-valid-tip {
    font-size: 14px;
    margin: -10px 0 10px 0;
  }

  .wpcf7-list-item {
    display: flex;
    align-items: center;
    margin: 0;

    input[type=checkbox] {
      width: 20px;
      height: 20px;
      margin: 0 20px 0 0;
    }
  }

  .submit-inner {
    display: flex;
    font-family: @font01;
    align-items: center;
  }

  .acceptance-block-text {
    font-size: 12px;
    width: 48%;

    a {
      color: @color04;

      &:hover {
        color: @colorlink;
      }
    }
  }

  .wpcf7-spinner {
    margin: 0 5px;
  }
}

.wsp-container {
  margin-top: 50px;

  .wsp-posts-title {
    display: none;
  }

  .wsp-category-title {
    display: block;
    font-size: 28px;
    margin-bottom: 10px;
  }

  ul {
    li {
      list-style-type: none;

      ul {
        li {
          margin-left: 25px;

          &:last-child {
            margin-bottom: 30px;
          }

          &:before {
            margin: 9px 15px 0 -25px;
            display: block;
            float: left;
            content: '';
            background: @color02;
            width: 9px;
            height: 9px;
            border-radius: 50%;
          }
        }
      }
    }
  }

  a {
    display: inline-block;
    padding: 5px 0;
    color: @color02;
    text-decoration: none;

    &:hover {
      color: @colorlink;
      text-decoration: underline;
    }
  }
}


/* Адаптивная верстка */
@media (max-width: 1200px) {
  .mainpage_posts {
    width: 100%;
  }
}

@media (max-width: 1023px) {

}

@media (max-width: 767px) {
  .page h1 {
    font-size: 35px;
    line-height: 45px;
    margin: 0;
  }

  .page-mainpage {
    .post_header {
      padding: 0;
    }

    h1 {
      font-size: 35px;
      line-height: 45px;
      margin: 30px 0 40px;
    }
  }

  .mainpage_posts {
    flex-wrap: wrap;
  }
}

@media (max-width: 480px) {

}