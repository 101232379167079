.posts {
  &-inner {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &:after {
      content: '';
      flex-basis: 31%;
    }
  }
}

.archive {
  .posts {
    padding: 50px 0;
  }
}

.post-item-medium {
  width: 350px;
  position: relative;
  margin-bottom: 30px;
  top: 0;
  transition: all 200ms;

  &-thumb-image {
    display: flex;
    justify-content: center;
    width: auto;
    height: 250px;
    border-radius: 10px;
    overflow: hidden;

    img {
      width: auto;
      height: 100%;
      border-radius: 10px;
      transition: 300ms;
    }
  }

  &-text {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  a {
    color: @color02;
    font-size: 24px;
    line-height: 30px;
    font-weight: 800;
    text-decoration: none;

    &:hover {
      color: @colorlink;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  &:hover {
    .post-item-medium-thumb-image img {
      transform: scale(1.05);
      transition: 300ms;
    }
  }
}

.wp-pagenavi {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 18px;
  margin: 30px 0 0;

  a, .pages, .page, .nextpostslink, .previouspostslink, .extend, .current {
    display: flex;
    width: 40px;
    height: 40px;
    margin: 0 10px 10px 0;
    padding: 0 15px;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    text-decoration: none;
    color: @color02;
    background-color: @color03;
    border-radius: 5px;
    transition: 300ms;
  }

  .pages {
    width: inherit;
    color: @color02;
  }

  .current {
    background: @colorlink;
    color: @color01;
  }

  a:hover {
    background: @colorlink;
    color: @color01;
  }
}

/* Адаптивная верстка */
@media (max-width: 1200px) {
  .post-item-medium {
    width: 32%;
  }
}

@media (max-width: 1023px) {

}

@media (max-width: 767px) {
  .archive {
    .post_header {
      padding: 20px 0 10px;

      p {
        margin-top: 0;
      }
    }
  }

  .post-item-medium {
    width: 100%;

    &-thumb-image {
      width: 100%;
      height: auto;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

@media (max-width: 480px) {

}