.post {
  &_header {
    padding: 30px 0;
    background-color: @color03;

    &_inner {
      width: @contentwidth;
      display: flex;
      flex-direction: column;
    }

    h1 {
      font-size: 45px;
      line-height: 55px;
      margin: 15px 0;
    }

    &_info {
      display: flex;
      align-items: center;
    }

    &_image {
      width: 700px;
      height: auto;

      img {
        width: auto;
        height: 100%;
        border-radius: 10px;
      }
    }

    &_text {
      margin: 0 auto;

      p {
        margin: 10px 0;
      }
    }
  }

  &_content {
    padding-bottom: 50px;

    .wrapper {
      width: 1000px;
      padding-right: 300px;
    }
  }

  &_text {

    blockquote {
      padding: 30px;
      margin: 30px 0;
      font-weight: 800;
      background: @color03;
      border-radius: 10px;

      p {
        margin: 0;
      }
    }

    a {
      color: @colorlink;
      font-weight: 700;

      &:hover {
        color: @colorhover;
      }
    }

    ol {
      margin: 30px 0;
      padding-left: 25px;
      counter-reset: list1;
      opacity: .8;

      li {
        margin: 10px 0;
        line-height: 25px;
        list-style-type: none;
      }

      li:before {
        counter-increment: list1;
        content: counter(list1) ".";
        margin: 0 0 0 -25px;
        display: block;
        float: left;
        height: 25px;
        width: 25px;
        font-weight: 800;
        color: @color02;
      }

      ol {
        counter-reset: list2;
        margin: 10px 0;

        li:before {
          counter-increment: list2;
          content: counter(list1) "." counter(list2) ".";
          margin-right: 15px;
        }
      }

      ul {
        margin: 10px 0;
      }
    }

    ul {
      margin: 30px 0;
      padding-left: 25px;

      li {
        margin: 10px 0;
        line-height: 25px;
        list-style-type: none;
      }

      li:before {
        margin: 9px 15px 0 -25px;
        display: block;
        float: left;
        content: '';
        background: @color02;
        width: 9px;
        height: 9px;
        border-radius: 50%;
      }

      ol {
        padding-left: 0;
        margin: 10px 0;

        li:before {
          counter-increment: list1;
          content: counter(list1) ".";
          background: none;
          border-radius: unset;
          margin: 1px 20px 0 0;
        }
      }

      ul {
        margin: 10px 0;
      }
    }

    img {
      width: 100%;
      height: auto;
      border-radius: 10px !important;
      max-width: 100%;
    }

    .wp-caption {
      display: block;
      text-align: center;
      margin: 30px 0;
      position: relative;
      clear: both;
      max-width: 100%;

      .wp-caption-text {
        font-size: 16px;
        color: @color02;
        text-transform: none;
        padding: 5px 0 !important;
        margin: 0 !important;
        line-height: 1.2;
        text-align: left;
        //font-style: italic;
        opacity: 0.6;
      }
    }

    .alignleft {
      float: left;
      margin: 10px 25px 25px 0;
      width: inherit !important;
    }

    .alignright {
      float: right;
      margin: 10px 0 10px 25px;
    }

    .aligncenter {
      display: block;
      margin: 20px auto;
      width: 100%;
      height: inherit;
    }

    iframe[src] {
      width: 100%;
      height: 400px;
      display: block;
      margin: 50px auto;
      border-radius: 10px !important;
      max-width: 100%;
    }
  }

  &_social {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &_view {
    opacity: 0.3;
  }
}

.post-date {
  margin-right: 50px;
  font-size: 14px;
  opacity: 0.5;
}

.post-info {
  margin: 0;
  display: flex;
  opacity: 0.5;

  &-inner {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    margin: 0 50px 0 0;

    &:last-child {
      margin-right: 0;
    }
  }

  .wtr-time-wrap {
    margin: 0;
    font-size: 14px;
    font-family: @font01;
    color: @color02;
  }
}

.toc {
  padding: 50px 0 20px;
  margin-bottom: 30px;

  &-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    max-width: @contentwidth;
  }

  &-title {
    font-size: 35px;
    font-weight: 800;
    margin-bottom: 20px;
  }

  &-item {
    font-size: 18px;
    font-weight: 400;
    padding: 10px 0;

    a {
      color: @color02;
      text-decoration: none;
      border-bottom: 1px solid @color02;

      &:before {
        content: '';
        background-color: @color02;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        float: left;
        display: inline-block;
        margin: 5px 10px 0 0;
      }

      &:hover {
        color: @colorlink;
        border-bottom: unset;
      }
    }
  }
}

.fotorama {
  &__arr {
    background-image: url('../img/icon-arrow.svg') !important;
    background-position: center !important;
    background-size: contain !important;
    width: 60px !important;
    height: 60px !important;
    margin-top: -30px !important;
    opacity: 0.5 !important;

    &:hover {
      opacity: 1 !important;
    }
  }

  &__arr--prev {
    transform: rotate(180deg) !important;
  }
}

.bioinfo {
  background-color: @color03;
  padding: 30px 30px 20px;
  margin: 30px 0;
  border-radius: 10px;

  &-title {
    display: flex;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  p {
    margin: 10px 0;
  }
}

.personrewards {
  margin-bottom: 50px;

  h2 {
    margin-bottom: 15px;
  }

  &-info {
    display: block;
  }

  ul {
    margin: 15px 0 30px 0;
  }

  &-date {
    margin-right: 5px;
  }

  &-text2 {
    font-size: 14px;
    font-style: italic;
    margin-left: 5px;
    opacity: 0.5;
  }
}

.perelink {
  padding: 50px 0 20px;
  background-color: @color03;

  &-title {
    display: block;
    font-size: 32px;
    font-weight: 800;
    margin-bottom: 30px;
  }
}


/* Адаптивная верстка */
@media (max-width: 1200px) {
  .post_content .wrapper {
    width: 80%;
    padding-right: 20%;
    max-width: 80%;
  }
}

@media (max-width: 1023px) {
  .post_header_inner {
    width: 100%;
  }

  .post_content .wrapper {
    width: 100%;
    padding-right: 0;
    max-width: 650px;
  }
}

@media (max-width: 767px) {
  .post_header h1 {
    font-size: 35px;
    line-height: 45px;
  }

  .post_header_info {
    align-items: flex-start;
    position: relative;
    flex-direction: column;
  }

  .post-info {
    position: absolute;
    right: 0;
    top: 0;
  }

  .post-date {
    margin: 10px 0 0;
  }

  .post_header_image {
    width: 100%;
    height: auto;

    img {
      width: 100%;
      height: auto;
    }
  }

  .post_content .wrapper {
    width: 90%;
  }

  .toc-title {
    font-size: 28px;
  }

  .post_text iframe[src] {
    height: 250px;
    margin: 30px auto;
  }

  .post_social {
    align-items: flex-start;
    flex-direction: column;
  }

  .post_view {
    margin-top: 20px;
  }
}

@media (max-width: 480px) {

}